import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { Divider, Stack, Tooltip, Typography, useTheme } from '@mui/material';

import {
  ContentBox,
  FlexBetween,
  FlexCenter,
  FlexColumn,
} from '../../../components';

import {
  authPersistSelectors,
  OpenAppsStatusBreakdownType,
  ResidentCountResult,
  systemPersistSelectors,
} from '../../../state';
import { InfoOutlinedIcon } from '../../../themes';
import {
  ApplicationStatusCategory,
  applicationStatusTypes,
  applicationTypes,
  dataTooltipStyles,
  pluralize,
} from '../../../lib';

import { ListQueryParams } from '../ListViews/helpers/SharedListConfig';
import { middleDot } from '../../../lib/symbols';
import { ColoredBox, generateLinkToResidentList, SectionHeader } from './utils';
import { getColorForApplicationStatus } from '../caseDetails/application/applicationHelpers';

export const WorkflowSummaries = memo(function ({
  residentCounts,
  selectedFacilityIds,
}: {
  residentCounts: ResidentCountResult;
  selectedFacilityIds: string[];
}) {
  const theme = useTheme();

  const isApplicationCompany = useSelector(
    authPersistSelectors.isApplicationCompany,
  );
  const applicationTypesEnums = useSelector(
    isApplicationCompany
      ? systemPersistSelectors.acApplicationTypes
      : systemPersistSelectors.facilityApplicationTypes,
  );
  const openApplicationStatuses = useSelector(
    systemPersistSelectors.openApplicationStatuses,
  );

  const otherAppTypesFilter = useMemo(() => {
    const otherAppTypes = applicationTypesEnums
      .map((at) => at.id)
      .filter(
        (id: string) =>
          id !== applicationTypes.NursingHome &&
          id !== applicationTypes.Renewal,
      );
    return { [ListQueryParams.applicationTypes]: otherAppTypes };
  }, [applicationTypesEnums]);

  // we can't use the hasOpenApplication flag because that checks for any open application, not the status of the current application
  const openAppStatusFilter = {
    [ListQueryParams.applicationStatuses]: openApplicationStatuses.map(
      (s) => s.id,
    ),
  };

  return (
    <ContentBox>
      <SectionHeader
        title1='workflow'
        title2='summaries'
        color={theme.palette.info.main}
      />
      <Stack divider={<Divider />} marginTop={3}>
        <WorkflowsResidentsListItem
          name='Nursing home applications'
          inProgress={{
            count: residentCounts.nursingHomeApps.openAppsCount,
            filters: {
              [ListQueryParams.applicationTypes]: applicationTypes.NursingHome,
              ...openAppStatusFilter,
            },
          }}
          needed={{
            count: residentCounts.nursingHomeApps.appsNeededCount,
            filters: {
              [ListQueryParams.applicationStatusCategories]:
                ApplicationStatusCategory.Needed,
              [ListQueryParams.applicationTypesNeeded]:
                applicationTypes.NursingHome,
            },
          }}
          breakdown={residentCounts.nursingHomeApps.openAppsStatusBreakdown}
          selectedFacilityIds={selectedFacilityIds}
        />

        <WorkflowsResidentsListItem
          name='Renewals'
          inProgress={{
            count: residentCounts.renewalApps.openAppsCount,
            filters: {
              [ListQueryParams.applicationTypes]: applicationTypes.Renewal,
              ...openAppStatusFilter,
            },
          }}
          needed={{
            count: residentCounts.renewalApps.appsNeededCount,
            filters: {
              [ListQueryParams.applicationStatusCategories]:
                ApplicationStatusCategory.Needed,
              [ListQueryParams.applicationTypesNeeded]:
                applicationTypes.Renewal,
            },
          }}
          breakdown={residentCounts.renewalApps.openAppsStatusBreakdown}
          selectedFacilityIds={selectedFacilityIds}
        />

        <WorkflowsResidentsListItem
          name='Appeals'
          inProgress={{
            count: residentCounts.appsInAppealCount,
            filters: {
              [ListQueryParams.applicationStatuses]:
                applicationStatusTypes.Appealed,
            },
          }}
          needed={{
            count: residentCounts.appealNeededCount,
            filters: {
              [ListQueryParams.applicationStatuses]:
                applicationStatusTypes.Denied,
            },
          }}
          selectedFacilityIds={selectedFacilityIds}
        />

        <WorkflowsResidentsListItem
          name='Other applications'
          inProgress={{
            count: residentCounts.otherTypesApps.openAppsCount,
            filters: { ...otherAppTypesFilter, ...openAppStatusFilter },
          }}
          needed={{
            count: residentCounts.otherTypesApps.appsNeededCount,
            filters: {
              [ListQueryParams.applicationStatusCategories]:
                ApplicationStatusCategory.Needed,
              ...otherAppTypesFilter,
            },
          }}
          breakdown={residentCounts.otherTypesApps.openAppsStatusBreakdown}
          selectedFacilityIds={selectedFacilityIds}
        />

        <WorkflowsResidentsListItem
          name='Document requests'
          inProgress={{
            count: residentCounts.docsDueCount,
            filters: { [ListQueryParams.hasDocsDue]: true },
          }}
          overdue={{
            count: residentCounts.overdueDocsCount,
            filters: {
              [ListQueryParams.hasDocsDue]: true,
              [ListQueryParams.hasOverdueDocs]: true,
            },
          }}
          selectedFacilityIds={selectedFacilityIds}
        />
      </Stack>
    </ContentBox>
  );
});

const linkStyles = {
  textDecoration: 'none',
  transition: 'opacity 300ms linear',
  '&:hover': { opacity: 0.5 },
};
const WorkflowsResidentsListItem = ({
  name,
  inProgress,
  needed,
  overdue,
  breakdown,
  selectedFacilityIds,
}: WorkflowsResidentsListItemProps) => {
  const isApplicationCompany = useSelector(
    authPersistSelectors.isApplicationCompany,
  );

  return (
    <FlexBetween paddingY={1} alignItems='center'>
      <Typography variant='subtitle2'>{name}</Typography>
      <FlexCenter gap={1}>
        <FlexCenter>
          <Typography
            variant='label'
            color='text.primary'
            component={Link}
            to={generateLinkToResidentList(
              selectedFacilityIds,
              inProgress.filters,
            )}
            sx={linkStyles}
          >
            {inProgress.count} {pluralize('resident', inProgress.count)} in
            progress
          </Typography>

          {breakdown && (
            <InfoTooltip
              title={name}
              totalCount={inProgress.count}
              data={breakdown}
            />
          )}
        </FlexCenter>

        {!isApplicationCompany && !!needed?.count && (
          <>
            <Typography>{middleDot}</Typography>
            <Typography
              variant='caption'
              color='text.secondary'
              component={Link}
              to={generateLinkToResidentList(
                selectedFacilityIds,
                needed.filters,
              )}
              sx={linkStyles}
            >
              {needed.count} needed
            </Typography>
          </>
        )}

        {!!overdue?.count && (
          <>
            {middleDot}
            <Typography
              variant='caption'
              color='error'
              component={Link}
              to={generateLinkToResidentList(
                selectedFacilityIds,
                overdue.filters,
              )}
              sx={linkStyles}
            >
              {overdue.count} overdue
            </Typography>
          </>
        )}
      </FlexCenter>
    </FlexBetween>
  );
};

const InfoTooltip = memo(function ({
  title,
  totalCount,
  data,
}: {
  title: string;
  totalCount: number;
  data: OpenAppsStatusBreakdownType;
}) {
  const openApplicationStatuses = useSelector(
    systemPersistSelectors.openApplicationStatuses,
  );

  const tooltip = (
    <FlexColumn gap={2} padding={1}>
      <Typography variant='subtitle2'>
        {totalCount} {title}
      </Typography>

      <FlexColumn gap={0.5}>
        {openApplicationStatuses.map((s, i) => {
          const { count } = data.find((d) => d.key === s.id) || {};
          return (
            <FlexCenter key={i} gap={0.5}>
              <ColoredBox
                color={getColorForApplicationStatus(s.id)?.color}
                size={10}
                borderRadius='2px'
              />
              <Typography variant='caption' color='text.secondary'>
                {count || 0} {s.name}
              </Typography>
            </FlexCenter>
          );
        })}
      </FlexColumn>
    </FlexColumn>
  );

  return (
    <Tooltip
      title={tooltip}
      placement='top-end'
      sx={{ ml: '4px' }}
      componentsProps={dataTooltipStyles}
    >
      <InfoOutlinedIcon fontSize='tiny' />
    </Tooltip>
  );
});

type CountAndFiltersType = {
  count: number;
  filters: Record<any, any>;
};
type BaseWorkflowSummaryType = {
  name: string;
  inProgress: CountAndFiltersType;
  breakdown?: OpenAppsStatusBreakdownType;
};
type WorkflowSummaryType =
  | (BaseWorkflowSummaryType & { needed: CountAndFiltersType; overdue?: never })
  | (BaseWorkflowSummaryType & {
      needed?: never;
      overdue: CountAndFiltersType;
    });
type WorkflowsResidentsListItemProps = {
  selectedFacilityIds: string[];
} & WorkflowSummaryType;
