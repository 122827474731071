const emptyObj = {};
export const caseSelectors = {
  casePeople: (state) => state.cases.people,
  caseNotes: (state) => state.cases.case.notes,
  /** case tag options specific to this case (checks applicable states) */
  companyCaseTagOptions: (state) => state.cases.companyCaseTagOptions,
  profile: (state) => state.cases.case.profileData.profile,
  profileData: (state) => state.cases.case.profileData || emptyObj,
  todos: (state) => state.cases.case.todos,
};
