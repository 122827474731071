import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import { hasAuthRequestToken } from '../../lib';

import {
  CreateCompany,
  ForgotPassword,
  GetToken,
  LoginPage,
  Logout,
  NotFoundPage,
  SetPassword,
  VerifyLoginPage,
} from '../../pages';

//lazy loading for route-based code splitting to reduce bundle size.
const AlertsPage = lazy(() => import('../../pages/main/AlertsPage'));
const AllUsers = lazy(() => import('../../pages/superAdmin/AllUsers'));
const Announcements = lazy(() =>
  import('../../pages/superAdmin/Announcements'),
);
const ApplicationCompanyIntake = lazy(() =>
  import('../../pages/intake/ApplicationCompanyIntake'),
);
const ApplicationsPage = lazy(() =>
  import('../../pages/main/ApplicationsPage'),
);
const CalendarPage = lazy(() => import('../../pages/main/CalendarPage'));
const CaseDetails = lazy(() =>
  import('../../pages/main/caseDetails/CaseDetails'),
);
const Companies = lazy(() => import('../../pages/superAdmin/Companies'));
const CompanyContactsPage = lazy(() =>
  import('../../pages/main/CompanyContactsPage'),
);
const DashboardPage = lazy(() => import('../../pages/main/DashboardPage'));
const FacilitiesDashboardPage = lazy(() =>
  import('../../pages/main/FacilitiesDashboardPage'),
);
const FacilitiesSuperAdmin = lazy(() =>
  import('../../pages/superAdmin/Facilities'),
);
const FacilitiesPage = lazy(() => import('../../pages/main/FacilitiesPage'));
const FacilityDetails = lazy(() =>
  import('../../pages/main/facilityDetails/FacilityDetails'),
);
const FacilityIntake = lazy(() => import('../../pages/intake/Intake'));
const MappingPage = lazy(() => import('../../pages/main/MappingPage'));
const RegionsPage = lazy(() => import('../../pages/admin/RegionsPage'));
const ResidentsBoard = lazy(() => import('../../pages/main/ResidentsBoard'));
const ResidentsPage = lazy(() => import('../../pages/main/ResidentsPage'));
const Settings = lazy(() => import('../../pages/account/settings/Settings'));
const UserProfilePage = lazy(() =>
  import('../../pages/account/UserProfilePage'),
);
const NotificationPreferences = lazy(() =>
  import('../../pages/account/NotificationPreferences'),
);
const UsersPage = lazy(() => import('../../pages/account/UsersPage'));
// end of lazy loading routes

export const PublicRoutes = {
  login: {
    path: '/account/login',
    element: hasAuthRequestToken() ? <Navigate to='/' /> : <LoginPage />,
  },
  verifyLogin: {
    path: '/account/verify-login',
    element: <VerifyLoginPage />,
  },
  forgotPassword: {
    path: '/account/password/forgot-password',
    element: <ForgotPassword />,
  },
  getToken: { path: '/ext/get-token', element: <GetToken /> },
  logout: { path: '/ext/logout', element: <Logout /> },
  setPassword: { path: '/account/password/reset', element: <SetPassword /> },
  home: { path: '/', element: <Navigate to='/dashboard' /> },
  notFound: { path: '/not-found', element: <NotFoundPage /> },
  catchAll: { path: '*', element: <NotFoundPage /> },
};

export const PrivateRoutes = {
  userProfile: {
    path: '/account/profile',
    element: <UserProfilePage />,
  },
  notificationPreferences: {
    path: '/account/notifications',
    element: <NotificationPreferences />,
  },
  dashboard: {
    path: '/dashboard',
    element: <DashboardPage />,
  },
  calendar: {
    path: '/calendar',
    element: <CalendarPage />,
  },
  alertsPage: {
    path: '/alerts',
    element: <AlertsPage />,
  },
  residentsList: {
    path: '/residents',
    element: <ResidentsPage />,
  },
  applications: {
    path: '/applications',
    element: <ApplicationsPage />,
  },
  board: {
    path: '/board',
    element: <ResidentsBoard />,
  },
  caseDetails: {
    path: '/cases/:id',
    element: <CaseDetails />,
  },
  facilityIntake: {
    path: '/residents/facility/intake',
    element: <FacilityIntake />,
  },
  applicationCompanyIntake: {
    path: '/residents/application-service/intake',
    element: <ApplicationCompanyIntake />,
  },
  facilities: {
    path: '/facilities',
    element: <FacilitiesPage />,
  },
  facilityDetails: {
    path: '/facilities/:id',
    element: <FacilityDetails />,
  },
  mappingPage: {
    path: '/mapping',
    element: <MappingPage />,
  },
  caseWorkers: {
    path: '/case-workers',
    element: <CompanyContactsPage />,
  },
  facilitiesDashboard: {
    path: '/facilities-dashboard',
    element: <FacilitiesDashboardPage />,
  },
  adminSettings: {
    path: '/admin/account/settings',
    element: <Settings />,
  },
  adminUsersPage: {
    path: '/admin/account/users',
    element: <UsersPage />,
  },
  regions: {
    path: '/admin/account/regions',
    element: <RegionsPage />,
  },
  superAdminUsersPage: {
    path: '/superAdmin/users',
    element: <AllUsers />,
  },
  createCompany: {
    path: '/superAdmin/create-company',
    element: <CreateCompany />,
  },
  companies: {
    path: '/superAdmin/companies',
    element: <Companies />,
  },
  announcements: {
    path: '/superAdmin/announcements',
    element: <Announcements />,
  },
  facilitiesSuperAdmin: {
    path: '/superAdmin/facilities',
    element: <FacilitiesSuperAdmin />,
  },
};

export const Routes = {
  ...PublicRoutes,
  ...PrivateRoutes,
};
