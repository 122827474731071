import { useCallback } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {
  uploadToPublicGCP,
  EditorSecureImageUploadHandler,
} from '../../../lib';

/** NOTE:
when using this wysiwyg within a modal, you need to add the following prop to the MODAL:
disableEnforceFocus={true} //need this in order for tinyMce to work fully within the modal
*/
export function TinyMceEditor({
  initialValue,
  statusbar = false,
  plugins = ' searchreplace autolink visualblocks link table advlist lists autoresize',
  menubar = 'edit  insert format table',
  toolbar = ' blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link | table | outdent indent| forecolor backcolor ',
  editorRef,
  onInit = () => {}, //optional function to run in onInit (besides for setting the ref)
  useSecureImageUpload = true,
  initProps = {},
  ...otherProps
}) {
  const onEditorImageUpload = useCallback(
    async (blobInfo, progress) => {
      const upload = blobInfo.blob();
      const file = {
        name: upload.name,
        file: upload,
      };
      const response = await uploadToPublicGCP(file, 'announcements');
      if (response?.url) {
        return response.url;
      }
    },

    [],
  );

  const onEditorSecureImageUpload = useCallback(async (blobInfo, progress) => {
    return await EditorSecureImageUploadHandler(blobInfo, progress, 'Notes');
  }, []);

  return (
    <Editor
      tinymceScriptSrc={process.env.REACT_APP_TINY_MCE_CDN}
      apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
      initialValue={initialValue}
      init={{
        promotion: false,
        statusbar: statusbar,
        plugins: plugins,
        autoresize_bottom_margin: 0,
        menubar: menubar,
        toolbar: toolbar,
        toolbar_mode: 'wrap',
        content_style: 'a { color: #096EF8; text-decoration: none }',
        contextmenu: false, //disable the context menu since chrome doesnt support auto accessing the clipboard, otherwise the only way to access copy/paste is via keyboard shortcuts
        removed_menuitems: 'copy paste cut pastetext', //remove these since they don't work, see above
        images_upload_handler: useSecureImageUpload
          ? onEditorSecureImageUpload
          : onEditorImageUpload,
        automatic_uploads: false,
        browser_spellcheck: true,
        ...initProps,
      }}
      onInit={(evt, editor) => {
        editorRef.current = editor;
        onInit();
      }}
      {...otherProps}
    />
  );
}
